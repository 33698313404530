//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { BaseLayout, AuthLayout, TwoFaLayout } from '../layouts';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import {
  LandingPage,
  ProfilePage,
  SearchPage,
  SignupPage,
  VerifyEmailPage,
  UserPasswordReset,
  VerifyNewEmailPage,
  Notifications,
  UserTwoFaReset,
  MyContentListPage,
  MyHistoryListPage,
} from '../pages';

import {
  PackagesListPage,
  PackageDetailsPage,
  InstantiationDetailsPage,
  InstantiationListPage,
} from '../pages/rosm';

import { MissingContent } from '../components/Pages/index';

/* ROS-M Component Imports */
import UndergoingMaintenance from '@rosm/rosm-ng-components/dist/components/UndergoingMaintenance/UndergoingMaintenance';
import { ErrorBoundary } from 'react-error-boundary';

import {
  DocumentationDetailsPage,
  DocumentationListPage,
} from '../pages/csr/index.js';

/** Global Context & Helper Function Imports */
import { Context, loadData, revalidateToken, logout } from '../helpers';
import { setIsValid } from '../helpers/context';

/** Messages */
import { USER_MESSAGES } from '../helpers/api/user/userMessages';
import { emailAdminOnError } from '../helpers/api/userRequests';

const ErrorFallback = (error) => {
  (async () => await emailAdminOnError(error))();
  return (
    <>
      <UndergoingMaintenance
        frontEndArea={true}
        message={USER_MESSAGES.unableToReachServer}
      />
    </>
  );
};

const AccessUnverifiedRoutes = ({ dispatch }) => {
  return (
    <Routes>
      <Route path='signup' element={<SignupPage />} />
      <Route
        path='verifyEmail/:verificationHash'
        element={<VerifyEmailPage />}
      />
      <Route
        path='verifyNewEmail/:verificationHash'
        element={<VerifyNewEmailPage />}
      />
      <Route path='/resetPassword/:resetHash' element={<UserPasswordReset />} />
      <Route path='/twoFaReset/:resetHash' element={<UserTwoFaReset />} />
      <Route path='/resetPassword' element={<UserPasswordReset />} />
      <Route index element={<AuthLayout dispatch={dispatch} />} />
      <Route path='/*' element={<AuthLayout dispatch={dispatch} />} />
    </Routes>
  );
};

const AccessPartialRoutes = () => {
  return (
    <Routes>
      <Route path='/twoFa' element={<TwoFaLayout />}></Route>
      <Route
        path='verifyNewEmail/:verificationHash/:requisitionId'
        element={<VerifyNewEmailPage />}
      />
      <Route path='/' element={<TwoFaLayout />}>
        <Route index element={<TwoFaLayout />} />
        <Route path='/*' element={<TwoFaLayout />} />
        <Route path='/twoFaReset/:resetHash' element={<UserTwoFaReset />} />
        <Route
          path='/*'
          element={
            <MissingContent title={'The requested url does not exist.'} />
          }
        />
      </Route>
    </Routes>
  );
};

const AccessFullRoutes = ({ user }) => {
  const canViewROSM = true;
  let canViewCSR = false;
  user.roles.forEach((element) => {
    if (element.startsWith('CSR') || element === 'ADMINISTRATOR') {
      canViewCSR = true;
    }
  });
  return (
    <Routes>
      <Route path={'/'} element={<BaseLayout user={user} />}>
        <Route index element={<LandingPage />} />
        <Route
          path='verifyNewEmail/:verificationHash/:requisitionId'
          element={<VerifyNewEmailPage />}
        />
        {(canViewROSM || canViewCSR) && (
          <>
            <Route
              path='myContent'
              element={<MyContentListPage user={user} />}
            />
          </>
        )}
        {/* ROSM */}
        {canViewROSM && (
          <>
            {/* Packages */}
            <Route path='packages' element={<PackagesListPage user={user} />} />
            <Route
              path='packages/:packageId'
              element={<PackageDetailsPage user={user} />}
            />
            {/* Instantiations */}
            <Route
              path='instantiations'
              element={<InstantiationListPage user={user} />}
            />
            <Route
              path='instantiations/:instantiationId'
              element={<InstantiationDetailsPage user={user} />}
            />
          </>
        )}
        {canViewCSR && (
          <>
            {/* Documentation / CSR */}
            <Route
              path='documentation/:documentationType'
              element={<DocumentationListPage user={user} />}
            />
            <Route
              path='documentation/:documentationType/:documentationId'
              element={<DocumentationDetailsPage user={user} />}
            />
          </>
        )}

        {/* Other */}
        <Route path='search' element={<SearchPage />} />
        <Route path='about' element={<LandingPage />} />
        <Route path='profile' element={<ProfilePage user={user} />} />
        <Route
          path='/resetPassword/:resetHash'
          element={<UserPasswordReset />}
        />
        <Route path='/twoFaReset/:resetHash' element={<UserTwoFaReset />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='myHistory' element={<MyHistoryListPage user={user} />} />
        <Route
          path='/*'
          element={
            <MissingContent title={'The requested route does not exist.'} />
          }
        />
      </Route>
    </Routes>
  );
};

function AppRoutes() {
  const { state, dispatch, setNotificationSnackBarMessage, token, setToken } =
    useContext(Context);
  const { loading, loaded, userData } = state;
  const [openTimeoutDialog, setOpenTimeoutDialog] = useState(false);
  const [minutesUntilExpiration, setMinutesUntilExpiration] = useState(30);
  const location = useLocation();

  /* Assign Global Context State References for Local Use */
  /* On First Mount: Set Token Validity, User, and Request from Server API (Once w/ No Dependency Reference) */
  useEffect(() => {
    loadData(dispatch);
  }, []);

  /* Create the interval to start counting down the expiration */
  useEffect(() => {
    const interval = setInterval(() => {
      setMinutesUntilExpiration((prev) => prev - 1);
    }, 60000);
    return () => clearInterval(interval); // Clear the function to prevent memory leaks.
  }, []);

  /* When user data is updated, store the token for easy access for expiration interval */
  useEffect(() => {
    if (userData?.token) {
      setToken(userData.token);
    }
  }, [userData]);
  /**
   * Set the user to be logged out if they choose to not stay logged in
   */
  const handleLogout = () => {
    const logoutUser = async () => {
      const result = await logout(setIsValid, dispatch, true);
      if (!result) {
        setNotificationSnackBarMessage(true, 'Unable to Logout', 'error');
        return;
      }
      setToken({});
    };
    logoutUser();
  };

  /* On update of minutes until expiration, show warning or logout */
  useEffect(() => {
    if (token.accountVerified) {
      // If session timed out then clear it out and logout the user, returning them to login screen
      if (minutesUntilExpiration <= 0) {
        handleLogout();
        window.location.href = '/'; // Load the base page
        return;
      }
      // Show the warning
      if (minutesUntilExpiration <= 5) {
        if (!openTimeoutDialog) {
          setOpenTimeoutDialog(true);
        }
      }
    }
  }, [minutesUntilExpiration]);
  // On each route taken, check if expiration time getting close and update token if within 20 minutes
  useEffect(() => {
    if (token.accessLevel === 'full') {
      // It is 10 minutes or less before the token expires and an interaction with the site has taken place, reauthorize the token (don't want to hit api on every call)
      if (minutesUntilExpiration <= 10) {
        revalidateToken(); // Get a new token
        loadData(dispatch); // Refresh the user data
      }
      setMinutesUntilExpiration(30); // Reset the minutes until expiration to 30
    }
  }, [location]);

  /**
   * Shows the timeout notification dialog
   * @returns component
   */
  const ShowTimeoutDialog = () => {
    /**
     * Closes the dialog
     */
    const handleClickCloseForm = () => {
      setOpenTimeoutDialog(false);
    };
    /**
     * Set the user to stay logged in by updating the token and resetting the expiration time
     */
    const handleStayLoggedOn = () => {
      revalidateToken(); // Get a new token
      setMinutesUntilExpiration(30);
      handleClickCloseForm();
    };
    return (
      <Dialog
        open={openTimeoutDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' sx={{ color: 'black' }}>
          Your Session Will Timeout Soon
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you wish to remain logged into the system?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStayLoggedOn} autoFocus>
            Yes
          </Button>
          <Button onClick={handleLogout}>Logout</Button>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * A listing of all available routes in a simplified structure
   * @returns all routes as a component named <RouterList />
   */
  if ((loading || !userData) && userData?.token?.accountVerified) {
    return (
      <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </div>
    );
  }

  const isUnverified =
    !userData?.token?.accountVerified || !userData?.token?.valid;
  const accessLevelData = userData?.token?.accessLevel;

  if (isUnverified) {
    return (
      <>
        {loaded && (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AccessUnverifiedRoutes dispatch={dispatch} />
          </ErrorBoundary>
        )}
      </>
    );
  } else if (accessLevelData === 'partial') {
    return (
      <>
        {loaded && (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AccessPartialRoutes />
          </ErrorBoundary>
        )}
      </>
    );
  } else if (accessLevelData === 'full') {
    return (
      <>
        {loaded && (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AccessFullRoutes
              user={userData.user}
              loaded={loaded}
              token={token}
            />
            <ShowTimeoutDialog />
          </ErrorBoundary>
        )}
      </>
    );
  }
  return (
    <>
      {loaded && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AccessUnverifiedRoutes dispatch={dispatch} />
        </ErrorBoundary>
      )}
    </>
  );
}

export default AppRoutes;
