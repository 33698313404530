//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { config } from '../../app/Config';

export * from './util';
export * from './user';
export * from './userRequests';
export * from './rosmRequests';
export * from './csrRequests';
export * from './twoFa';
export * from './userLogin';
export * from './images';
export * from './category';

export const REACT_APP_SERVER = config.REACT_APP_SERVER;
export const BACKEND_HOST = `${REACT_APP_SERVER}`;
export const BASE_URL_REQUESTS = `${REACT_APP_SERVER}/requests`;
export const BASE_URL_USER = `${REACT_APP_SERVER}/user`;
export const HIGHLIGHTS_LIST_URL = `${BACKEND_HOST}/landing/highlights`;
export const UPLOAD_URL = `${REACT_APP_SERVER}/upload`;
export const SEARCH_URL = `${BACKEND_HOST}/search`;
export const CATEGORY_URL = `${BACKEND_HOST}/rosm/categories`;

// Base images url
export const BASE_IMAGES_URL = `${BACKEND_HOST}/content/images`;

// CSR - requires content type addition
export const BASE_CSR_DOCUMENTATION_URL = `${BACKEND_HOST}/csr/documentation`;

// ROSM
export const ROSM_PACKAGES_URL = `${BACKEND_HOST}/rosm/packages`;
export const ROSM_CATEGORIES_URL = `${BACKEND_HOST}/rosm/categories`;
export const ROSM_INSTANTIATIONS_URL = `${BACKEND_HOST}/rosm/instantiations`;

// User Content
export const USER_CONTENT_URL = `${BACKEND_HOST}/myContent`;
